// Authentication service actions goes here.....
import { Constants } from "./ENVIROMENT_VARIABLES";

export class Token {

  constructor() {
    this.validateTPSession();
    this.changeLoginIcon();
    this.getUserTokenFlag();
    this.logOut();
   // //console.log("login status for user :- " + this.getUserTokenFlag() );
  }

  // getToken() {
  //   var baseUrl = Constants.BASE_URL;
  //   var userToken = getCookie("User_Token");
  //   Constants.IsAuthenticated = false;
  //   if (
  //     userToken === "" ||
  //     userToken == undefined ||
  //     Constants.IsAuthenticated == false
  //   ) {
  //     $.ajax({
  //       type: "POST",
  //       url: baseUrl + "Token",
  //       dataType: "json",
  //       data: {
  //         grant_type: "client_credentials",
  //         client_id: Constants.API_KEY,
  //       },
  //       // headers: {
  //       //     'Accept': 'application/json',
  //       //     'Access-Control-Allow-Origin': 'http://127.0.0.1:5500',
  //       //     'Access-Control-Allow-Methods': '*',
  //       //     'Access-Control-Allow-Headers': 'Content-Type , Authorization',
  //       // },
  //       // crossDomain: true,
  //       // xhrFields: {
  //       //     withCredentials: false,
  //       // },

  //       // beforeSend: function(xhr){
  //       //     xhr.setRequestHeader('Authorization', 'Bearer ' + userToken);
  //       // },
  //       error: function (xhr, status, error) {
  //         $.post(
  //           baseUrl + "Token",
  //           {
  //             grant_type: "client_credentials",
  //             client_id: Constants.API_KEY,
  //           },
  //           (response) => {
  //             let Temp_Token = response.access_token;
  //             const expiredAt = new Date(Object.entries(response)[6][1]);
  //             //// //console.log(expiredAt )
  //             // const Token_ExpireDate = expiredAt.toLocaleString().substring(0, 10);
  //             //// //console.log(Token_ExpireDate )
  //             // docCookies.setItem(Token);
  //             setToSession(Temp_Token, expiredAt);
  //             validateSession();
  //             document.cookie =
  //               "Temp_Token=" + Temp_Token + "; " + "expires=" + expiredAt;
  //             ("path=/");
  //            // //console.log("Temp_Token=" + Temp_Token);
  //            // //console.log("need user Token");
  //             // Constants.IsAuthenticated=true;
  //            // //console.log(Constants.IsAuthenticated);
  //           }
  //         );
  //       },
  //       success: function (data) {
  //         let Temp_Token = data.access_token;
  //         const expiredAt = new Date(Object.entries(data)[6][1]);
  //         setToSession(Temp_Token, expiredAt);
  //         validateSession();
  //         document.cookie =
  //           "Temp_Token=" + Temp_Token + "; " + "expires=" + expiredAt;
  //         ("path=/");
  //         // Constants.IsAuthenticated=true;
  //         // sessionStorage.setItem('Token', JSON.stringify({'exp': new Date(response.expires).toISOString , 'Token':userToken}));
  //         // docCookies.setItem(Token);
  //         // document.cookie = "Temp_Token=" + Temp_Token + "; " +"expires=" + expiredAt;"path=/";
  //         // setToSession(Temp_Token, expiredAt);
  //         // validateSession();
  //       },
  //     });
  //   } else {
  //     validateSession();
  //     Constants.IsAuthenticated = true;
  //    // //console.log(Constants.IsAuthenticated);
  //   }
  //   return JSON.parse(sessionStorage.getItem("seassionObj"));
  // }

  setUserTokenFlag(IsAuthenticated) {
    this.IsAuthenticated = IsAuthenticated;
    localStorage.setItem("AUTH_STATUS", String(this.IsAuthenticated));
  }
  getUserTokenFlag() {
    let AUTH_STATUS = localStorage.getItem("AUTH_STATUS");
    let seassionVal = sessionStorage.getItem('TPP_Token');
    if(seassionVal == null ){
     // //console.log("test")
      this.setUserTokenFlag(false);
      // document.getElementById("user-toggler-button").style.display="none";
      document.getElementById("signin-dropdown").classList.remove("hidden");

      return false;
    }
    else {
      if(this.validateTPPSession()==true){
        this.setUserTokenFlag(true);
        // document.getElementById("signin-dropdown").style.display="none";
        document.getElementById("user-dropdown").classList.remove("hidden");


        return true;
      }
      else{
        this.setUserTokenFlag(false);
        // document.getElementById("user-toggler-button").style.display="none";
        document.getElementById("signin-dropdown").classList.remove("hidden");

        return false;
      }
    }
  }

  changeLoginIcon(){
    if(this.getUserTokenFlag()==true){
      $("#user-dropdown").removeClass("d-none");
      $(".signin-dropdown").addClass("d-none");
    }
    else{
      this.logOut();
      $('#addToCart_counter').text('0')
    }
      

  }
  logOut(){
    const originUrl = window.location.origin;

    $("#logout").on('click', () => {
      document.getElementById("user-dropdown").classList.add("hidden");

      document.getElementById("signin-dropdown").classList.remove("hidden");
      sessionStorage.removeItem('TPP_Token');
      this.setUserTokenFlag(false); 
      $('#addToCart_counter').text('0')
      window.location.href = `${originUrl}/index.html`;
    });
  }

  validateTPPSession() {
    let seassionVal = sessionStorage.getItem('TPP_Token');
    if (seassionVal !== null) {
      let sessionObj = JSON.parse(seassionVal);
      let expiredAt = new Date(sessionObj.expiredAt);
      // var days = 14;
      // var newDate = new Date(Date.now() + days * 24*60*60*1000);
      //// //console.log(new Date());
      //// //console.log(newDate);
      if (expiredAt > new Date()) {
       // //console.log("date")
       // //console.log(expiredAt < new Date())
        // Validate expiry date. for only 14 days

        return true;
      } 
      else {
        sessionStorage.removeItem('TPP_Token');
        this.setUserTokenFlag(false);
        window.location.href = `${location.origin}/login.html`;
        return false;
      }
    }
    else
     {
      sessionStorage.removeItem('TPP_Token');
      return null;
     }
  }

  //TP Token
  returnTP_Token() {
    $.ajax({
      type: "POST",
      url: `${Constants.BASE_URL}oauth/token`,
      'Content-Type': "application/x-www-form-urlencoded",
      data: {
        username: Constants.username,
        password: Constants.password,
        grant_type: "password",
        client_id: Constants.client_id,
        client_secret: Constants.client_secret
      },
      error: function (xhr, status, error) {
        $.post(`${Constants.BASE_URL}oauth/token`, {
          username: Constants.username,
          password: Constants.password,
          grant_type: "password",
          client_id: Constants.client_id,
          client_secret: Constants.client_secret
        },
        (response) => {
          const Temp_Token = response.access_token;
          const expireDate = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString();
          //console.log(expireDate);

          let obj = {
            Token: Temp_Token,
            expiredAt: expireDate,
          };
          sessionStorage.setItem("TP_Token", JSON.stringify(obj));
        });
      },
      success: function (data) {
        const Temp_Token = data.access_token;
        //console.log(data.access_token);
        const expireDate = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString();
        let obj = {
          Token: Temp_Token,
          expiredAt: expireDate,
        };
        sessionStorage.setItem('TP_Token', JSON.stringify(obj));
      },
    });
  }

  validateTPSession() {
    let sessionVal = sessionStorage.getItem('TP_Token');
    if (sessionVal !== null) {
      let sessionObj = JSON.parse(sessionVal);
      let expiredAt = new Date(sessionObj.expiredAt);
      if (expiredAt > new Date()) {
        return true;
      } 
      else {
        sessionStorage.removeItem('TP_Token');
        this.returnTP_Token();
        return false;
      }
    }
    else {
      sessionStorage.removeItem('TP_Token');
      this.returnTP_Token();
      return null;
    }
  }

  
  setToSession(Token,tokenName) {
    // let expiredAt = new Date(new Date().getTime() + (60000 * inMin));
    expireDate=new Date(new Date().setDate(new Date().getDate() + 1)).toISOString();
  let obj = {
    Token: Token,
    expiredAt: expireDate,
  };
  sessionStorage.setItem(tokenName, JSON.stringify(obj));
  }


}



 new Token();
